import React from 'react'

export default function SiteOff() {

    return (
        <>
        
        <h1> Site Offline </h1>

        </>
    )
}